import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getCategory } from "../../../data/data";

export const Card = (props) => {
  const navigate = useNavigate();
  const { cardItem } = props;

  return (
    <Container
      thumbNail={cardItem.thumbnail}
      onClick={() => {
        navigate(`/test-main/${cardItem.id}`);
      }}
    >
      <Title>{cardItem.title}</Title>
      <CategoryLabel>
        {getCategory(cardItem.categoryId)
          ? getCategory(cardItem.categoryId).name
          : ""}
      </CategoryLabel>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  aspect-ratio: 700/466;

  border-radius: 16px;
  border: 1px solid var(--light-gray-200, #edeeef);
  background: url(${(prev) => prev.thumbNail});
  background-position: center;
  background-size: cover;

  overflow: hidden;

  cursor: pointer;
`;

const CategoryLabel = styled.div`
  position: absolute;
  width: max-content;
  padding: 6px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 200;
  line-height: 1;
  cursor: default;

  top: 16px;
  left: 16px;

  background-color: white;
  color: black;
`;

const Title = styled.pre`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 40%,
    rgba(255, 255, 255, 0) 74%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 16px;
  padding-bottom: 16px;
  color: white;

  font-family: 'GyeonggiBatang';
  font-size: 18px;
  font-weight: 200;
`;
