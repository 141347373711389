import Q1ThumbNail from "../../assets/test/test3/thumbnail.png";
import Type0 from "../../assets/test/test3/1.png";
import Type1 from "../../assets/test/test3/2.png";
import Type2 from "../../assets/test/test3/3.png";
import Type3 from "../../assets/test/test3/4.png";

/**
 * 인터페이스 설명
 * {
 *    id: number -------------------- 테스트 식별 아이디
 *    categoryId: number ------------ 카테고리 아이디 (data.js 파일에 있습니다.)
 *    thumbnail: string ------------- 카드 썸네일 입니다. 위에서 import 합니다.
 *    title: string ----------------- 테스트 제목입니다.
 *    subTitle: string -------------- 테스트 부 제목입니다.
 *    description: string ----------- 테스트 설명 입니다.
 *    totalQuestion: number --------- 총 질문 개수 입니다. (없어도 무방, 현재 사용하지 않음)
 *    kakaoShareId: number ---------- 카카오 공유 템플릿 ID
 *    shareImage: string ------------ 카카오 공유 이미지
 *    subjectQuestion: {
 *        subject : string ----------------- 주제 (사용 하지 않음)
 *        shortLabel: string --------------- 라벨 (사용 하지 않음)
 *        positiveResult: string ----------- + 워딩 (사용 하지 않음)
 *        negativeResult: string ----------- - 워딩  (사용 하지 않음)
 *        qeustions: {
 *            no: number -------------------------- 문제 번호
 *            content: string --------------------- 질문
 *            answer: {
 *                constents: string --------------------------- 답변
 *                value: 1 | -1 ------------------------------- 값
 *            }
 *        }[] <----배열이라는 뜻
 *    }[]
 *    result: {
 *        type: string -------------- 결과 type,
 *        const: number ------------- 결과 카운트 (결과 식별자로 사용)
 *        image: string ------------- 결과 이미지 입니다. 위에서 import 합니다.
 *        description: string ------- 결과 설명 입니다.
 *    }[]
 * }
 */
export const test3 = {
  id: 3,
  categoryId: 3,
  thumbnail: Q1ThumbNail,
  title: "인풋 중독 테스트",
  subTitle: "아웃풋없는 인풋에 지쳤다면",
  description: `뭔가, 이것저것 해왔다고 생각하는데...\n내 인풋의 이유는 무엇이고, 어떻게 나아가야 할까?\n\n14개의 질문을 통해, 4개의 유형 중\n본인에게 가장 적합한 결과지를 받아보게 됩니다.`,
  totalQuestion: 14,
  kakaoShareId: 102380,
  shareImage:
    "https://i.ibb.co/9WRZTZs/thumbnail.jpg",
  subjectQuestion: [
    {
      subject: "인풋의 이유",
      shortLabel: "나는 왜, 인풋을 하는가",
      positiveResult: "그냥 불안하니까",
      negativeResult: "해야하니까 강제로",
      questions: [
        {
          no: 1,
          content: "인풋. 즉 내가 시간을 들여 공부하는 이유\n책을 읽는다거나. 강의를 듣는다거나...",
          answer: [
            {
              contents: "그냥 괜히 불안하잖아.\n심난한데, 여기저기서 호들갑을 떠니까 '나도 해야하나' 싶어",
              value: 1,
            },
            {
              contents: "상황상 해야만 하니까 하는거지... \n이것도 해야하고, 이것 끝나면 저것도 해야 해",
              value: -1,
            },
          ],
        },
        {
          no: 2,
          content: "내가 '새로운 무언가'에 뛰어들때, 내 머릿속 상황.\n예를 들어, ChatGPT 공부를 시작한다면.",
          answer: [
            {
              contents: "잘 모르지만, 안 하면 안되겠지...\n이제 AI의 시대라는데 최소한의 지식은 쌓아놔야지.",
              value: 1,
            },
            {
              contents: "솔직히 배울 생각 없었는데, \n이것 배우면, 이런식으로 응용 가능하겠는데? 오호",
              value: -1,
            },
          ],
        },
        {
          no: 3,
          content: "그저그런 웹소설 작가인 당신.\n더 끌리는 선택지는? (단, 하나만 고를 수 있다면)",
          answer: [
            {
              contents: "나와는 거리가 먼, 인기몰이중인 새로운 장르. \n 그 장르에 쉽게 입문할 수 있는 방법.",
              value: 1,
            },
            {
              contents: "지금 내가 쓰고 있는 소설. 시너지가 날만한 글감 아이디어 \n '인기가 많아질지도 몰라...'",
              value: -1,
            },
          ],
        },
        {
          no: 4,
          content: "올해들어, 내가 공부하고 - 경험한 것들.\n되돌아본다면...",
          answer: [
            {
              contents: "교집합 없이 가로로 나열된 느낌이 강하지.\n사진 촬영법, 영어공부, 미니멀리즘 인테리어...",
              value: 1,
            },
            {
              contents: "어느정도는 교집합이 있는 것 같은데?\n매력적인 글쓰기, 에세이형 글쓰기, 소설 작법...",
              value: -1,
            },
          ],
        },
        {
          no: 5,
          content: "스스로 생각하기에 어떠한 이미지에 더 가까운지.\n더 선호하는 이미지 말고, '현재의 나' 그대로",
          answer: [
            {
              contents: "아, 그것도 해봤고 이것도 해봤어요.\n 트랜드나 흐름에 관심을 갖는 편입니다. 잘하진 못해도.",
              value: 1,
            },
            {
              contents: "어디서 보긴 했는데, 잘 아는건 아니고요. \n그나마 저는 이런걸 잘 하긴 해요. 남들보다는 아니여도.",
              value: -1,
            },
          ],
        },
        {
          no: 6,
          content: "당신이 호감을 느끼는 인플루언서 A.\n두달마다 강조하는 것이 달라진다. \n\n저번에는 이것 이번에는 요것...",
          answer: [
            {
              contents: "아휴 힘들다. 바쁘다 바뻐... \n 참 신경쓸게 많네. 그치만 내가 부족하니까...",
              value: 1,
            },
            {
              contents: "어설프게 건드려봤자, 남는게 없을 것 같은데... \n 걸러서 듣자. 각자 상황이 다르니까.",
              value: -1,
            },
          ],
        },
        {
          no: 7,
          content: "내가 더 민감하게 반응하는 상황은?\n 즉, 더 스트레스 받는 환경은...",
          answer: [
            {
              contents: "내가 모르는, 처음 듣는 개념 A\n나 빼고 다 아는듯한 분위기.",
              value: 1,
            },
            {
              contents: "내가 잘 아는, 그나마 내 전문 분야인 B \n이 분야에서 새로 공부해야 하는 무언가의 등장 ",
              value: -1,
            },
          ],
        },
      ],
    },
    {
      subject: "내 수준",
      shortLabel: "내 수준",
      positiveResult: "지키는 자",
      negativeResult: "승리해야만 하는 자",
      questions: [
        {
          no: 15,
          content: "티를 안 냈을 뿐이지, 나는 항상 심각했다.\n내 고민의 공통점이 뭐냐고? 바로...",
          answer: [
            {
              contents: "'그냥 이렇게, 무난하게 흘러가면 좋을텐데\n별다른 일 터지지 말고 그냥...'",
              value: 1,
            },
            {
              contents: "'이렇게 끝나면 안 돼, 나는 더 잘할 수 있어. \n변화가 필요해. 역전... 까지는 아니더라도",
              value: -1,
            },
          ],
        },
        {
          no: 16,
          content: "중대한 일은 아닌데, 사소하지만 은근히 더 신경쓰이는 일",
          answer: [
            {
              contents: "휴대폰의 잔기스,\n흰색 티셔츠에 묻은 얼룩 ",
              value: 1,
            },
            {
              contents: "돈이 충분치 않은데, 휴대폰을 바꿀까?\n그냥 눈 딱감고 살까 말까...",
              value: -1,
            },
          ],
        },
        {
          no: 17,
          content: "솔직해지자. 나, 그리고 주위 사람들... \n굳이 '우열'이라거나, '수준'을 나눈다면...",
          answer: [
            {
              contents: "내가 여러모로 낫다.\n조금 더 우위에 있어. 허세가 아니라 그냥 그렇다고.",
              value: 1,
            },
            {
              contents: "아직은 언더독이지 나는. \n보고 배울 수 있는 사람들이 많아서 좋아.",
              value: -1,
            },
          ],
        },
        {
          no: 18,
          content: "내가 더 몰입하는 콘텐츠는?",
          answer: [
            {
              contents: "비리 정치인끼리의 싸움.\n느와르물스럽기도 하고, 무튼 지키려는 자들의 싸움.",
              value: 1,
            },
            {
              contents: "평범한 주인공이 우연히(혹은 노력으로) 능력을 갖게 됨\n자, 이제 시작이다!",
              value: -1,
            },
          ],
        },
        {
          no: 19,
          content: "나를 지치게하는 감정의 뿌리를 찾아가본다면...?",
          answer: [
            {
              contents: "하기 싫어. 책임지기 싫어.\n해야 할 일이 많고, 내가 싫어하는 일이야.",
              value: 1,
            },
            {
              contents: "혼란스러워.\n내 삶에는 선택지가 너무 많아. 근데 고만고만해...",
              value: -1,
            },
          ],
        },
        {
          no: 20,
          content: "내가 더 민감하게 반응하는 말은?\n제발 내 신경을 긁지 마...",
          answer: [
            {
              contents: "에이... 요즘 누가 그런걸 해?\n세상의 기준이 달라지고 있잖아.",
              value: 1,
            },
            {
              contents: "슬슬 제대로 된 일을 알아봐야지?\n시간, 꽤 많이 지났잖아?",
              value: -1,
            },
          ],
        },
        {
          no: 21,
          content: "도피한다고 불러도 좋아.\n나는 불안할 때, 이런 유형의 망상을 해.",
          answer: [
            {
              contents: "최소한의 인과관계가 있는, 행복회로 망상.\n팀장님이 갑자기 내게 이런 기회를 준다거나...",
              value: 1,
            },
            {
              contents: "인과관계보다는, '내가 원하는 결과' 상상 \n 그 수단, 과정은 모르겠지만 '꿈을 이룬 나'를 그리곤 해",
              value: -1,
            },
          ],
        },
      ],
    },
  ],
  result: [
    {
      type: "",
      count: 0,
      image: Type2,
      description:
        "",
    },
    {
      type: "",
      count: 1,
      image: Type3,
      description:
        "",
    },
    {
      type: "",
      count: 2,
      image: Type0,
      description:
        "",
    },
    {
      type: "",
      count: 3,
      image: Type1,
      description:
        "",
    },
  ],
};
