import React from "react";
import styled from "styled-components";
import { categoryType } from "../../../data/data";

export const Category = (props) => {
  const { filter, setFilter } = props;

  const onClickCategory = (categoryId) => {
    setFilter(categoryId);
  };
  return (
    <Container>
      <Title>카테고리</Title>
      <CategoryList>
        <CategoryItem
          className={`0` === `${filter}` ? "selected" : ""}
          onClick={() => onClickCategory(0)}
        >
          All
        </CategoryItem>
        {categoryType.map((item) => {
          return (
            <CategoryItem
              className={`${item.id}` === `${filter}` ? "selected" : ""}
              onClick={() => onClickCategory(item.id)}
            >
              {item.name}
            </CategoryItem>
          );
        })}
      </CategoryList>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 36px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 200;
`;

const CategoryList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

const CategoryItem = styled.div`
  width: max-content;
  padding: 12px 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  : 400;
  line-height: 1;
  cursor: pointer;

  background-color: rgba(243, 244, 244, 1);

  &.selected {
    background-color: #353638;
    color: white;
  }
`;
