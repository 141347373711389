import Q1ThumbNail from "../../assets/test/test2/thumbnail.png";
import Type0 from "../../assets/test/test2/1.png";
import Type1 from "../../assets/test/test2/2.png";
import Type2 from "../../assets/test/test2/3.png";
import Type3 from "../../assets/test/test2/4.png";

/**
 * 인터페이스 설명
 * {
 *    id: number -------------------- 테스트 식별 아이디
 *    categoryId: number ------------ 카테고리 아이디 (data.js 파일에 있습니다.)
 *    thumbnail: string ------------- 카드 썸네일 입니다. 위에서 import 합니다.
 *    title: string ----------------- 테스트 제목입니다.
 *    subTitle: string -------------- 테스트 부 제목입니다.
 *    description: string ----------- 테스트 설명 입니다.
 *    totalQuestion: number --------- 총 질문 개수 입니다. (없어도 무방, 현재 사용하지 않음)
 *    kakaoShareId: number ---------- 카카오 공유 템플릿 ID
 *    shareImage: string ------------ 카카오 공유 이미지
 *    subjectQuestion: {
 *        subject : string ----------------- 주제 (사용 하지 않음)
 *        shortLabel: string --------------- 라벨 (사용 하지 않음)
 *        positiveResult: string ----------- + 워딩 (사용 하지 않음)
 *        negativeResult: string ----------- - 워딩  (사용 하지 않음)
 *        qeustions: {
 *            no: number -------------------------- 문제 번호
 *            content: string --------------------- 질문
 *            answer: {
 *                constents: string --------------------------- 답변
 *                value: 1 | -1 ------------------------------- 값
 *            }
 *        }[] <----배열이라는 뜻
 *    }[]
 *    result: {
 *        type: string -------------- 결과 type,
 *        const: number ------------- 결과 카운트 (결과 식별자로 사용)
 *        image: string ------------- 결과 이미지 입니다. 위에서 import 합니다.
 *        description: string ------- 결과 설명 입니다.
 *    }[]
 * }
 */
export const test2 = {
  id: 2,
  categoryId: 1,
  thumbnail: Q1ThumbNail,
  title: "자아 포지셔닝 테스트",
  subTitle: "'척'하는 것들을 기반으로",
  description: `스스로도 내가 왜 이러는지 모를 때.\n\n내가 은근히 조성하려는 분위기는 과연?\n클래식한 성공에 대한 반응, 내 취약한 모습을 기반으로.\n\n14개의 질문을 통해, 4개의 유형 중\n본인에게 가장 적합한 결과지를 받아보게 됩니다.`,
  totalQuestion: 14,
  kakaoShareId: 102380,
  shareImage:
    "https://i.ibb.co/9WRZTZs/thumbnail.jpg",
  subjectQuestion: [
    {
      subject: "애쓰는 모습을 보여준다는것",
      shortLabel: "낑낑거리기",
      positiveResult: "보여주면안된다",
      negativeResult: "보여줘도된다",
      questions: [
        {
          no: 1,
          content: "솔직히 나는 좀 이런 것 같다.\n 만약 누가봐도 '대단한 성과'를 내가 이뤄냈다면.",
          answer: [
            {
              contents: "별 것 아닌척, 운이 좋았던 것처럼 은근한 자랑.\n실제로는 노력했지만.",
              value: 1,
            },
            {
              contents: "내가 노력했다는 것을 털어놓는다.\n부족했던 내가, 이걸 위해 얼마나 노력했는지...",
              value: -1,
            },
          ],
        },
        {
          no: 2,
          content: "태어나면서부터 정해지는 것들. \n뭐, 외모라거나, 물려받는 재산이라거나, 혈통 등.",
          answer: [
            {
              contents: "가치있고 의미있지 그 무엇보다. \n후천적인 노력으로는 얻어낼 수 없는 것들의 값어치.",
              value: 1,
            },
            {
              contents: "각자의 성장 서사가 더 중요한거 아니야? \n저마다의 흉터, 스토리, 여정이 더 비싸지.",
              value: -1,
            },
          ],
        },
        {
          no: 3,
          content: "내가 누군가에게 호감을 느끼는 순간은\n이성적인 판단보다, 그냥 본능적으로 끌리는 순간은.",
          answer: [
            {
              contents: "그 사람의 외적인 환경. \n'이런 환경에서 자랐다면, 이러이러하겠구나.'",
              value: 1,
            },
            {
              contents: "그 사람의 내적인 아픔. \n'밝은 줄만 알았는데, 이렇구나'(반대 포함).",
              value: -1,
            },
          ],
        },
        {
          no: 4,
          content: "내가 즐겨보는 콘텐츠의 흐름\n더 정확히는 내가 '동일시하려는 인물'은",
          answer: [
            {
              contents: "어려운 일을 표정 하나 안 바뀌고 해낸다.\n다재다능 학생회장 전교1등이라거나.",
              value: 1,
            },
            {
              contents: "조금 부족한데, 나름의 매력이 있는 인물\n사람냄새가 나는 인물이 좋더라 나는.",
              value: -1,
            },
          ],
        },
        {
          no: 5,
          content: "솔직히 지금의 내 가치관은 이래.\n정말 솔직히 이야기하자면",
          answer: [
            {
              contents: "기회만 된다면,\n'끝내주는 환경에서 태어난 이들'그룹에 끼고 싶어.",
              value: 1,
            },
            {
              contents: "내 부족함을 드러내며 사람들에게 힘이 되어주고 싶어.\n물론, 가끔은 비아냥도 듣겠지만",
              value: -1,
            },
          ],
        },
        {
          no: 6,
          content: "당신은 작곡을 잘해요. 음원 차트에 매번 올라가요. \n누가 물어봐요. 비결이 뭔가요? ",
          answer: [
            {
              contents: "영감이 떠오를때가 있어요.\n그 영감을 붙잡고, 그자 리에서 끝을 봐요.",
              value: 1,
            },
            {
              contents: "솔직히 그런 말이 무서워요.\n다음 곡에서 바로 '퇴물' 소리를 들을 수 있잖아요.",
              value: -1,
            },
          ],
        },
        {
          no: 7,
          content: "내가 더 쉽게 용서할 수 있는 상황은\n (둘 다 최선을 다했다는 가정)",
          answer: [
            {
              contents: "실제로 실력이 뛰어난 A. 호언장담을 한다.\n믿고 맡겼는데, 결과가 영 별로.",
              value: 1,
            },
            {
              contents: "항상 열심히 노력하는 B. 기회를 달라고 한다. \n믿고 맡겼는데, 결과가 영 별로.",
              value: -1,
            },
          ],
        },
      ],
    },
    {
      subject: "클래식한 성공에 대해서",
      shortLabel: "클래식새로운",
      positiveResult: "새로운 성공",
      negativeResult: "클래식한 성공",
      questions: [
        {
          no: 15,
          content: "내가 인플루언서 A를 좋아한다. 혹은 존경. \n왜일까? 단순 호감을 넘어 존경까지 한다면.",
          answer: [
            {
              contents: "본인만의 차별화된 아이텐티티가 확고하니까",
              value: 1,
            },
            {
              contents: "고학력 전문직 고소득 등\n일반적인 성공 카테고리에 포함되니까",
              value: -1,
            },
          ],
        },
        {
          no: 16,
          content: "내, 행동의 제약 지점을 돌아본다면.\n나는 언제 주저하더라?",
          answer: [
            {
              contents: "구독자도, 팔로워도, 팬도 없는데. \n'떠들 자격'이 있을까?",
              value: 1,
            },
            {
              contents: "학위도, 전문성도 아무것도 없는데 나는.\n'떠들 자격'이 있을까?",
              value: -1,
            },
          ],
        },
        {
          no: 17,
          content: "내가 더 스트레스 받는 상황은?\n신경 거슬리고, 괜히 짜증나고.",
          answer: [
            {
              contents: "나보다 훨씬 나이가 많은 이의, \n'당시의 기준'에 맞춰진 잔소리.",
              value: 1,
            },
            {
              contents: "뭐 갑자기 튀어나와서, \n'내 성공 비법'을 따라하라고 외치는 이의 메시지",
              value: -1,
            },
          ],
        },
        {
          no: 18,
          content: "'퍼스널브랜딩' 이 시장은 솔직히...'",
          answer: [
            {
              contents: "새롭게 생겨난, 누구나 각자의 개성, 강점을 수익화하는 시장.",
              value: 1,
            },
            {
              contents: "2부 리그지.\n메인스트림에서는 상대가 안 되니까. 말장난이지.",
              value: -1,
            },
          ],
        },
        {
          no: 19,
          content: "다음의 두가지 상황.\n어떤 종류의 기회를 원할까 나는..",
          answer: [
            {
              contents: "온오프라인을 막론하고,\n많은 사람들이 내 이야기에 귀를 기울여주는 상황.",
              value: 1,
            },
            {
              contents: "내가 바라는 시험 하나.\n 통과할 가능성이 비약적으로 높아지는 상황.",
              value: -1,
            },
          ],
        },
        {
          no: 20,
          content: "이런 종류의 벽에 더 많이 가로막힌 것 같다.\n내 삶을 돌아보면 말이지.",
          answer: [
            {
              contents: "기발한 아이디어를 떠올리지 못해서,\n나라는 사람의 개성을 표현해내지 못해서.",
              value: 1,
            },
            {
              contents: "성적이 부족해서, 학위가 없어서,\n'다들 아는' 회사에 다니지 못해서.",
              value: -1,
            },
          ],
        },
        {
          no: 21,
          content: "이런 종류의 테스트. 원치 않는 결과가 나온다면 \n 머릿속에 드는 생각은.",
          answer: [
            {
              contents: "촉촉한마케터. 닉네임에서 느껴지잖아.\n제정신이 아니야 얘는. 아휴 시간낭비.",
              value: 1,
            },
            {
              contents: "하, 스트레스받는다. \n 나는 왜 이럴까. 내가 잘못하고 있는건가.",
              value: -1,
            },
          ],
        },
      ],
    },
  ],
  result: [
    {
      type: "",
      count: 0,
      image: Type2,
      description:
        "",
    },
    {
      type: "",
      count: 1,
      image: Type3,
      description:
        "",
    },
    {
      type: "",
      count: 2,
      image: Type0,
      description:
        "",
    },
    {
      type: "",
      count: 3,
      image: Type1,
      description:
        "",
    },
  ],
};
