import React, { useEffect } from "react";
import styled from "styled-components";

const DisplayAdsStatic = () => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        // console.log({ adsbygoogle })
        adsbygoogle.push({});
      } catch (e) {
        console.error(e);
      }
    };

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd();
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-5113702954144664"
        data-ad-slot="9562839257"
        data-ad-format="fluid"
        data-full-width-responsive="true"
      ></ins>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 10%;
  border: 1px solid var(--light-gray-200, #edeeef);
`;

export default DisplayAdsStatic;
