import React, { useEffect } from "react";
import styled from "styled-components";

const DisplayAds = () => {
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        // console.log({ adsbygoogle })
        adsbygoogle.push({});
      } catch (e) {
        console.error(e);
      }
    };

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd();
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <ins
      className="adsbygoogle"
      style={{
        display: "block",
        maxWidth: "100%", // 최대 너비를 조절

       
      }}
      data-ad-client="ca-pub-5113702954144664"
      data-ad-slot="2811578122"
      data-ad-format="fluid"
      data-ad-layout-key="-f0+59+6o-d2+2t"
      data-full-width-responsive="true"
    ></ins>
  
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;


  border-radius: 16px;
  border: 1px solid var(--light-gray-200, #edeeef);
  cursor: pointer;
`;


export default DisplayAds;
