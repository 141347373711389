import styled from "styled-components";

import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { allTest } from "../../data/data";
import {Oval} from "react-loader-spinner";
import DisplayAdsStatic from "../../components/DisplayAdsStatic";

import { MutatingDots } from "react-loader-spinner";

export const Test = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const questionId = useRef(undefined);
  const subject = useRef(undefined);
  const resultCount = useRef(undefined);

  const [data, setData] = useState(undefined);
  const [questionData, setQuestionData] = useState(undefined);
  const [currentNumber, updateCurrentNumber] = useState(1);
  const [showLoading, updateShowLoading] = useState(false);

  const nextQuestion = (subjectType, value) => {
    subject.current[subjectType] += value;
    if (currentNumber >= questionData.length) {
      resultCount.current = parseInt(
        subject.current.map((item) => (item > 0 ? "1" : "0")).join(""),
        2
      );
      console.log(subject.current);
      console.log(resultCount.current);
      updateShowLoading(true);
      return;
    }
    updateCurrentNumber(currentNumber + 1);
  };

  useEffect(() => {
    const id = location.pathname.split("/")[2];
    questionId.current = id;
    if (allTest) {
      setData(allTest.find((item) => `${item.id}` === `${id}`));
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const _questions = [];
      const _subject = [];
      data.subjectQuestion.forEach((item, index) => {
        _subject.push(0);
        item.questions.forEach((question) => {
          _questions.push({ ...question, subjectType: index });
        });
      });
      subject.current = _subject;
      setQuestionData(_questions);
    }
  }, [data]);

  useEffect(() => {
    if (!showLoading) return;
    setTimeout(() => {
      navigate(
        `/main-test/${questionId.current}/result/${resultCount.current}`
      );
    }, 3000);
  }, [showLoading]);

  if (!data || !questionData) return null;

  return (
    <TestContainer>
      <ProgressContainer>
        <ProgressBar
          style={{ width: `${(currentNumber / questionData.length) * 100}%` }}
        />
      </ProgressContainer>
      <QuestionContainer>
        <Question>{questionData[currentNumber - 1].content}</Question>
        {questionData[currentNumber - 1].answer.map((item) => {
          return (
            <QuestionAnswer
              onClick={() => {
                nextQuestion(
                  questionData[currentNumber - 1].subjectType,
                  item.value
                );
              }}
            >
              {item.contents}
            </QuestionAnswer>
          );
        })}
      </QuestionContainer>
      <DisplayAdsStatic />
      {showLoading && (
        <Dimd>

          
           <style>
      {`
        /* 텍스트 스타일 지정 */
        div {
          color: white;
          padding: 10px; /* Add some padding for spacing */
        }
      `}
    </style>
          <MutatingDots color="#FFFFFF" />
          <div>잠시만 기다려주세요.</div>
        </Dimd>
      )}
    </TestContainer>
  );
};

const TestContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

`;

const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;

  background-color: #ccc;

  border-radius: 10px;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #333;
  transition: width 0.2s ease;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;
`;

const Question = styled.pre`
  text-align: center;
  font-family: 'GyeonggiBatang';
  font-size: 16px;
  : 400;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 24px;
  letter-spacing: 0.7px; /* Adjust the value as needed */
  line-height: 1.2; /* 행간을 두 배로 조정 */


`;

const QuestionAnswer = styled.pre`

/* Mouseover effect */
  &:hover {
    background-color: #ececec; /* Change this color to your preference */
  }

  /* Click effect */
  &:active {
    transform: scale(0.98); /* Slightly scale down the element */
  }

  min-height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  text-align: center;
  margin: 0;
  margin-top: 16px;

  font-family: 'GyeonggiBatang';
  font-size: 14px;
  line-height: 20px;
  font-weight: 200;

  border-radius: 24px;
  border: 1px solid rgba(209, 213, 219, 1);
  transition: all 0.2s ease;

  cursor: pointer;

  user-select: none;
  /* &:hover {
    border-color: rgba(59, 130, 246, 1);
    color: rgba(59, 130, 246, 1);
  } */
`;

const Dimd = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 20px;
  font-weight: 300;
  color: white;
`;
