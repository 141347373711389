import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Card } from "../main/component/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { allTest } from "../../data/data";
import DisplayAds from "../../components/DisplayAdsFlex";
import DisplayAdsshorts from "../../components/DisplayAdsFlexshorts";

export const TestMain = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const location = useLocation();
  const questionId = useRef(undefined);
  const [data, setData] = useState(undefined);
  useEffect(() => {
    const id = location.pathname.split("/")[2];
    questionId.current = id;
    console.log(id);
    if (allTest) {
      console.log(window.scrollY);
      setData(allTest.find((item) => `${item.id}` === `${id}`));
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log(ref);
    if (ref.current) {
      ref.current.scrollTo({ top: 0 });
    }
  }, [data]);

  if (!data) return null;

  return (
    <Wrapper ref={ref}>
      <Container>
        <pre
          style={{
            fontFamily: "GyeonggiBatang",
            fontSize: 25,
            font: 600,
            marginTop: 36,
          }}
        >
          {data.title}
        </pre>
        <br />
        <div
          style={{
            fontFamily: "GyeonggiBatang",
            fontSize: 18,
            fontWeight: 400,
            color: "gray",
          }}
        >
          {data.subTitle}
        </div>
        <Thumbnail src={data.thumbnail} />

        <DisplayAds />
        <br />

        <InfoContainer>
          <pre
            style={{
              fontFamily: "GyeonggiBatang",
              textAlign: "start",
              fontSize: 15.5,
              fontWeight: 400,
            }}
          >
            {data.description}
          </pre>
        </InfoContainer>
        <StartBtn
          onClick={() => {
            if (!questionId) return;

            navigate(`/test-main/${questionId.current}/test`);
          }}
        >
          시작하기
        </StartBtn>
        </Container>
        <TestList>
          {allTest
            .filter(
              (item) =>
                item.categoryId === data.categoryId && item.id !== data.id
            )
            .map((item, index) => {
              return (
                <FlexColumn>
                  {index % 3 === 0 && <DisplayAds />}
                  <Card key={index} cardItem={item} />
                </FlexColumn>
              );
            })}
          {allTest.filter(
            (item) => item.categoryId === data.categoryId && item.id !== data.id
          ).length === 0 && <DisplayAds />}
        </TestList>
      





    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1px;
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Thumbnail = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  margin: 24px 0px;
  border-radius: 15px; // 이 값을 조정하여 모서리의 둥근 정도를 변경할 수 있습니다

`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: 1.35; /* 행간을 두 배로 조정 */

`;

const StartBtn = styled.div`
  width: 222px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 30px;
  border: 0;

  background-color: #133337;

  margin: 0 auto;
  margin-top: 36px;

  font-size: 17.5px;
  font-weight: 400;
  color: white;

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: all 0.2s ease;

  &:hover {
    background-color: #130a3e;
  }
`;

const TestList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 48px;
  padding-bottom: 24px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;