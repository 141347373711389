import styled from "styled-components";
import { Category } from "./component/Category";
import { Card } from "./component/Card";
import { allTest } from "../../data/data";
import { useEffect, useState } from "react";
import DisplayAds from "../../components/DisplayAdsFlex";

export const Main = () => {
  const [filter, setFilter] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <Category filter={filter} setFilter={setFilter} />
      <TestList>
        {allTest
          .filter((item) => {
            if (filter === 0) return true;
            return item.categoryId === filter;
          })
          .map((item, index) => {
            return (
              <FlexColumn>
                <Card key={index} cardItem={item} />
              </FlexColumn>
            );
          })}
      </TestList>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TestList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 36px;
  padding-bottom: 24px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
